<template>
  <div>
    <!-- Loading and Error if no speaker found -->
    <details-loading
      v-if="!speaker"
      type="Speaker"
      router-to="Speakers"
      :data="allSpeakers"
    ></details-loading>

    <!-- There is a speaker, display it -->
    <div class="row mt-4" v-else>
      <div class="col-12">
        <aad-back to="Speakers" text="View All Speakers"></aad-back>
      </div>

      <aad-title class="w-100">{{ speaker.DisplayName }}</aad-title>

      <!-- Fullscreen if there are no extra details, of col-md-8 if there are details and social links -->

      <div class="col-lg-4 p-1">
        <img
          :src="speaker.PhotoURL"
          alt="Image of Speaker"
          v-if="speaker.PhotoURL"
          class="speaker-photo"
        />
        <img
          :src="getSpeakerPicture(speaker.PersonId)"
          v-else
          :alt="'image of' + speaker.DisplayName"
          class="speaker-photo"
        />
        <div class="meeting-app-info-block" v-if="anyDetails">
          <div class="meeting-app-info-header row">
            <h4>Speaker Information</h4>
          </div>
          <div class="meeting-app-info-body">
            <div class="row meeting-app-info-item" v-if="speaker.Facebook_URL">
              <a :href="speaker.Facebook_URL">Facebook</a>
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Twitter_URL">
              <a :href="speaker.Twitter_URL">Twitter</a>
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Linkedin_URL">
              <a :href="speaker.Linkedin_URL">LinkedIn</a>
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Website">
              <a :href="speaker.Website">Website</a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-1 col-lg-8">
        <div v-if="speaker.Bio">
          <h4>Speaker Bio:</h4>
          <p>{{ speaker.Bio }}</p>
        </div>

        <div class="meeting-app-info-block w-100">
          <div class="meeting-app-info-header row">
            <h4>Session Schedule</h4>
          </div>

          <div
            class="meeting-app-info-body"
            v-if="speaker.Sessions && speaker.Sessions.length > 0"
          >
            <div v-for="session in speaker.Sessions" :key="session.SessionId">
              <div class="row meeting-app-info-item">
                <div>
                  <router-link
                    :to="{
                      name: 'SessionDetails',
                      params: { id: session.SessionId },
                    }"
                    class="text-link-light-bg"
                  >
                    <span class="font-weight-bold">
                      {{ session.Name }}
                    </span>
                  </router-link>
                  -
                  <session-time-string
                    type="full-range"
                    :session="session"
                  ></session-time-string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="meeting-app-info-block w-100" v-if="speaker.Disclosures">
          <div class="meeting-app-info-header row">
            <h4>Disclosures</h4>
          </div>

          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="disclosure in speaker.Disclosures.replaceAll(
                '\&ndash\;',
                '-'
              ).split(';')"
              :key="disclosure"
            >
              <span v-if="disclosure.trim().startsWith('no financial')"
                >No financial relationships exist with commercial
                interests.</span
              >
              <span v-else>{{ disclosure }}</span>
            </div>
          </div>
        </div>

        <!-- Handouts -->
        <div
          class="meeting-app-info-block"
          v-if="speaker.Handouts && speaker.Handouts.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Handouts</h4>
          </div>
          <div class="meeting-app-info-body" v-if="isLoggedIn">
            <div
              class="row meeting-app-info-item"
              v-for="handout in speaker.Handouts"
              :key="handout.HandoutURL"
            >
              <a :href="handout.HandoutURL" target="_blank">{{
                handout.HandoutTitle
              }}</a>
            </div>
          </div>
          <div class="meeting-app-info-body" v-else>
            <div class="row meeting-app-info-item">
              <router-link
                :to="{
                  name: 'Login',
                }"
                class="text-link-light-bg"
              >
                Login to View Handouts
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn" class="w-100">
        <hr />
        <attendee-notes
          class="w-100"
          ref="notes"
          note-type="speaker"
          :link-id="speaker.id"
          :title="speaker.DisplayName"
        ></attendee-notes>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailsLoading from "@/components/DetailsLoading";
import SessionTimeString from "@/components/SessionTimeString";
import AttendeeNotes from "@/components/AttendeeNotes";

export default {
  data() {
    return {
      speakerId: this.$route.params.id,
    };
  },
  metaInfo() {
    return {
      title: this.speaker ? this.speaker.DisplayName : "Speaker Details",
    };
  },
  mounted() {
    this.getSpeakers();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLoggedIn && this.$refs.notes.hasUnsavedChanges) {
      if (confirm("You have unsaved notes, do you want to leave this page?")) {
        // said to leave anyways.
        next();
      } else {
        next(false);
      }
    } else {
      // no unsaved changes
      next();
    }
  },
  methods: {
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getSpeakerPicture(speakerId) {
      let meetingCode = this.$store.state.meetingCode;
      let url = `https://lms.aad.org/MeetingSpeaker/GetPhoto?eventId=${meetingCode}&personId=${speakerId}`;
      return url;
    },
  },
  components: {
    DetailsLoading,
    SessionTimeString,
    AttendeeNotes,
  },
  computed: {
    ...mapGetters(["allSpeakers", "getSpeakerById", "isLoggedIn"]),
    speaker() {
      return this.getSpeakerById(this.speakerId);
    },

    anyDetails() {
      return (
        this.speaker.Facebook_URL ||
        this.speaker.Linkedin_URL ||
        this.speaker.Website ||
        this.speaker.Twitter_URL
      );
    },
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      this.speakerId = this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.speaker-photo {
  border-radius: 5px;
  max-width: 200px;
  width: 100%;
  margin: auto;
  display: block;
}
</style>
